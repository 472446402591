import request from '../Axios'

// 新增主题活动
export const activityAdd = data => {
  return request({
    url: '/template/activity',
    method: 'post',
    data
  })
}

// 修改主题活动
export const activityEdit = data => {
  return request({
    url: '/template/activity',
    method: 'put',
    data
  })
}

// 删除主题活动
export const activityDelete = params => {
  return request({
    url: `/template/activity/${params.id}`,
    method: 'delete'
  })
}


// 活动列表
export const activityList = data => {
  return request({
    url: '/template/activity',
    method: 'get',
    params: data
  })
}

// 查询单个主题活动
export const activityEvery = params => {
  return request({
    url: `/template/activity/${params.id}`,
    method: 'get'
  })
}

// 常量
export const constantAuery = params => {
  return request({
    url: `/template/constant/query/${params.className}`,
    method: 'get'
  })
}
