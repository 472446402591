<template>
  <div class="user">
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="addActivity">创建主题活动</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #action>
        <el-table-column label="操作" width="290" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="handleClickLook(scoped.row)"
            >
              详情
            </el-button>
            <el-button
              type="primary"
              size="small"
              v-if="scoped.row.shelvesStatus == 1"
              @click="handleClickEdit(scoped.row)"
            >
              修改
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleClickDelete(scoped.row)"
            >
              删除
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleClickStatus(scoped.row)"
            >
              {{ scoped.row.shelvesStatus == 0 ? '下架' : '上架' }}
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="addVisible"
      :title="visibleTitle"
      width="50%"
      class="user_dialog"
      :before-close="addClose"
    >
      <div class="writeoff_box">
        <span><i>*</i>活动主标题：</span>
        <el-input
          v-model="addForm.title"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>活动副标题：</span>
        <el-input
          v-model="addForm.subtitle"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box remark_box">
        <span><i>*</i>活动描述：</span>
        <el-input
          v-model="addForm.description"
          :rows="5"
          type="textarea"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>活动类型：</span>
        <el-select
          v-model="addForm.type"
          class="dialog_input"
          placeholder="请选择"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="writeoff_box">
        <span>活动状态：</span>
        <el-select
          v-model="addForm.shelvesStatus"
          class="dialog_input"
          placeholder="请选择"
          :disabled="visibleTitle == '新增活动'"
        >
          <el-option
            v-for="item in shelvesStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>排序：</span>
        <div class="dialog_input">
          <el-input
            v-model="addForm.sort"
            type="text"
            placeholder="请输入"
            @keyup="numberInput1"
          ></el-input>
          <el-tooltip
            class="box-item"
            effect="dark"
            content="数字越大显示位置越靠前"
            placement="top"
          >
            <I name="QuestionFilled" class="tipIcon"></I>
          </el-tooltip>
        </div>
      </div>
      <div class="writeoff_box">
        <span>卡券名称：</span>
        <el-input
          v-model="couponName"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>用户初始卡券数量：</span>
        <el-input
          v-model="couponCount"
          type="text"
          placeholder="请输入"
          class="dialog_input"
          @keyup="numberInput2"
        ></el-input>
      </div>
      <template #footer v-if="visibleTitle != '查看详情'">
        <span class="dialog-footer">
          <el-button type="primary" @click="addSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  activityAdd,
  activityEdit,
  activityDelete,
  activityList,
  activityEvery,
  constantAuery
} from '@/api/theme'
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入标题或者副标题'
    },
    {
      title: '上下架状态',
      label: 'status',
      type: 'options',
      options: [],
      placeholder: '请选择'
    },
    {
      title: '活动类型',
      label: 'type',
      type: 'options',
      options: [],
      placeholder: '请选择'
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    status: '',
    type: ''
  },
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'title', label: '主题活动名称' },
    { prop: 'subtitle', label: '副标题' },
    { prop: 'description', label: '简介' },
    { prop: 'typeLabel', label: '活动类型', width: 100 },
    { prop: 'shelvesStatusLabel', label: '上下架状态', width: 100 },
    { slot: 'action' }
  ],
  addVisible: false,
  visibleTitle: '',
  addForm: {
    title: '',
    subtitle: '',
    description: '',
    type: '',
    shelvesStatus: 1,
    sort: ''
  },
  couponName: '',
  couponCount: 1,
  id: '',
  typeList: [],
  shelvesStatusList: []
})

onMounted(() => {
  getDataList()
  getDict()
})

// 只能输入数字
const numberInput1 = () => {
  addForm.value.sort = addForm.value.sort.replace(/[^\d]/g, '')
}
const numberInput2 = () => {
  couponCount.value = couponCount.value.replace(/[^\d]/g, '')
}

// 活动类型、变更积分类型
const getDict = () => {
  constantAuery({
    className: 'ActivityType'
  }).then(({ data: res }) => {
    if (res.code == 200) {
      let list = res.data
        .map(item => {
          return {
            label: item.value,
            value: item.code
          }
        })
      typeList.value = list
      formLabels.value[2].options = list.filter(item => item.value != 2 && item.value != 3)
    } else {
      ElMessage.error(res.msg)
    }
  })
  constantAuery({
    className: 'ActivityShelvesStatus'
  }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      shelvesStatusList.value = list
      formLabels.value[1].options = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    status: '',
    type: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.keyword = fromData.value.keywords
  }
  if (fromData.value.status !== '') {
    data.shelvesStatus = fromData.value.status
  }
  if (fromData.value.type !== '') {
    data.type = fromData.value.type
  }
  activityList(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 新增活动弹窗
const addActivity = () => {
  visibleTitle.value = '新增活动'
  addVisible.value = true
}

// 新增活动弹窗关闭
const addClose = () => {
  addForm.value = {
    title: '',
    subtitle: '',
    description: '',
    type: '',
    shelvesStatus: 1,
    sort: ''
  }
  couponName.value = ''
  couponCount.value = 1
  addVisible.value = false
}

// 新增活动弹窗提交
const addSubmit = () => {
  if (addForm.value.title == '') {
    ElMessage.warning('请输入活动主标题')
    return false
  }
  if (addForm.value.subtitle == '') {
    ElMessage.warning('请输入活动副标题')
    return false
  }
  if (addForm.value.description == '') {
    ElMessage.warning('请输入活动描述')
    return false
  }
  if (addForm.value.type == '') {
    ElMessage.warning('请选择活动类型')
    return false
  }
  if (addForm.value.sort == '') {
    ElMessage.warning('请输入排序')
    return false
  }
  if (couponCount.value == '') {
    ElMessage.warning('请输入卡券数量')
    return false
  }
  const data = addForm.value
  data.coupon = {
    couponName: couponName.value,
    couponCount: couponCount.value
  }
  if (data.title.indexOf('-') == -1) {
    data.title = data.title.substr(0, 2) + '-' + data.title.substr(2)
  }
  if (id.value == '') {
    activityAdd(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        addClose()
        getDataList()
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    data.id = id.value
    activityEdit(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        addClose()
        getDataList()
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

// 上下架
const handleClickStatus = row => {
  activityEvery({ id: row.id }).then(({ data: res }) => {
    if (res.code == 200) {
      addForm.value = res.data
      id.value = res.data.id
      let msg =
        row.shelvesStatus == 0
          ? '下架后，该活动将在小程序中不可见。是否继续？'
          : '上架后，该活动将在小程序展示。是否继续？'
      ElMessageBox.confirm(`${msg}`, '提示：', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let data = addForm.value
          data.id = id.value
          data.coupon = {
            couponName: res.data.coupon?.couponName,
            couponCount: res.data.coupon?.couponCount
          }
          data.shelvesStatus = addForm.value.shelvesStatus == 0 ? 1 : 0
          activityEdit(data).then(({ data: res }) => {
            if (res.code == 200) {
              ElMessage.success(res.msg)
              getDataList()
            } else {
              ElMessage.error(res.msg)
            }
          })
        })
        .catch(() => {})
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 编辑
const handleClickEdit = row => {
  activityEvery({ id: row.id }).then(({ data: res }) => {
    if (res.code == 200) {
      visibleTitle.value = '修改活动'
      addForm.value = res.data
      couponCount.value = res.data.coupon?.couponCount
      couponName.value = res.data.coupon?.couponName
      id.value = res.data.id
      addVisible.value = true
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 查看详情
const handleClickLook = row => {
  activityEvery({ id: row.id }).then(({ data: res }) => {
    if (res.code == 200) {
      visibleTitle.value = '查看详情'
      addForm.value = res.data
      couponCount.value = res.data.coupon?.couponCount
      couponName.value = res.data.coupon?.couponName
      id.value = res.data.id
      addVisible.value = true
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 删除
const handleClickDelete = row => {
  ElMessageBox.confirm('删除后该数据无法恢复，是否确认?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      activityDelete({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  addVisible,
  addForm,
  typeList,
  shelvesStatusList,
  visibleTitle,
  id,
  couponName,
  couponCount
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.user_dialog {
  .writeoff_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    span {
      width: 136px;
      text-align: right;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      i {
        color: red;
        font-size: 20px;
      }
    }
    .dialog_input,
    .el-radio-group {
      width: 50%;
      position: relative;
    }
    .tipIcon {
      position: absolute;
      right: -30px;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 22px !important;
      cursor: pointer;
    }
  }
  .remark_box {
    align-items: flex-start;
  }
  .block {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
}
</style>
